import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../../ALL Css/ALL Dashboard CSS/MyProfile.css";


const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const MyProfile = () => {
  const [photo, setPhoto] = useState(null);
  const [profileData, setProfileData] = useState({
    name: "",
    email: "",
    mobile: "",
    whatsapp: "",
    active: "Y",
  });
  const [showAddressForm, setShowAddressForm] = useState(false);
  const [addressData, setAddressData] = useState({
    add1: "",
    add2: "",
    city: "",
    state: "",
    pin: "",
    country: "",
  });
  const [addressList, setAddressList] = useState([]);
  const [showKYCForm, setShowKYCForm] = useState(false);
  const [kycData, setKycData] = useState([]);
  const [showKYCData, setShowKYCData] = useState(false);
  const [Dataform, setDataForm] = useState({
    docType: "",
    docNumber: "",
    attachment: null,
  });
  

  useEffect(() => {
    const userDetails = JSON.parse(sessionStorage.getItem("user"));
    if (userDetails?.EmailID) {
      axios
        .post(`${API_BASE_URL}/api/profile`, { EmailID: userDetails.EmailID })
        .then((response) => {
          const { UserName, EmailID, Mobile, WhatsApp, UserImage } = response.data;
          setProfileData({
            name: UserName,
            email: EmailID,
            mobile: Mobile,
            whatsapp: WhatsApp,
            active: "Y",
          });
          if (UserImage) {
            setPhoto(`${API_BASE_URL}${UserImage}`);
          }
        })
        .catch((error) => {
          console.error("Error fetching profile data", error);
        });
    } else {
      console.error("No email ID found in session storage");
    }
  }, []);

  const handleProfileSubmit = (e) => {
    e.preventDefault();
  
    const formData = new FormData();
    formData.append('EmailID', profileData.email);
    formData.append('UserName', profileData.name);
    formData.append('Mobile', profileData.mobile);
    formData.append('WhatsApp', profileData.whatsapp);
    formData.append('Active', 'Y'); 
  
    if (photo instanceof File) {
      formData.append('image', photo);
    }
  
    axios
      .post(`${API_BASE_URL}/api/profile/update`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        alert('Profile updated successfully!');
        if (response.data.UserImage) {
          setPhoto(response.data.UserImage);
        }
      })
      .catch((error) => {
        console.error('Error updating profile:', error);
        alert('Failed to update profile.');
      });
  };
  

  const handleProfileChange = (e) => {
    const { name, value } = e.target;
    setProfileData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handlePhotoUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setPhoto(file);
    }
  };
  
  

  // Toggle address form visibility
  const toggleAddressForm = () => {
    setShowAddressForm(!showAddressForm);
  };

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    setAddressData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Address Submit
const handleAddressSubmit = (e) => {
  e.preventDefault(); 
  if (Object.values(addressData).every((field) => field.trim() !== "")) {
    setAddressList((prevList) => [...prevList, addressData]); // Add new address to the list
    setAddressData({
      add1: "",
      add2: "",
      city: "",
      state: "",
      pin: "",
      country: "",
    }); 
    setShowAddressForm(true);
  } else {
    alert("Please fill in all fields");
  }
};



   // Toggle KYC form visibility
   const toggleKYCForm = () => {
    setShowKYCForm(!showKYCForm);
    setShowKYCData(false);
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setDataForm({
      ...Dataform,
      [name]: value
    });
  };

  const handleFileChange = (e) => {
    setDataForm({
      ...Dataform,
      attachment: e.target.files[0]
    });
  };

 // KYC Submit
const handleKYCSubmit = (e) => {
  e.preventDefault(); 
  if (Dataform.docType && Dataform.docNumber && Dataform.attachment) {
    setKycData((prevData) => [...prevData, Dataform]); 
    setDataForm({
      docType: "",
      docNumber: "",
      attachment: null,
    }); 
    setShowKYCForm(true); 
    setShowKYCData(true); 
  } else {
    alert("Please fill in all fields");
  }
};


  return (
    <div className="Profile-container">
      <form className="profile-form"
       onSubmit={handleProfileSubmit}>
        <div className="form-left">
          <div className="row">
            <div className="col-md-6">
              <div className="Form-Group">
                <label htmlFor="name">Name:</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={profileData.name}
                  onChange={handleProfileChange}
                  placeholder="Your name"
                />
              </div>
            </div>
            <div className="col-md-6">
            <div className="photo-upload">
            <div className="Photo-Preview">
              {photo ? (
                <img src={photo} alt="Profile" className="profile-photo" />
              ) : (
                <div className="photo-placeholder">No Photo</div>
              )}
            </div>
            <label htmlFor="photo-upload" className="edit-icon">
            ✏️
            </label>
            <input
              type="file"
              id="photo-upload"
              accept="image/*"
              onChange={handlePhotoUpload}
              className="photo-input"
            />
          </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="Form-Group">
                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={profileData.email}
                  onChange={handleProfileChange}
                  placeholder="Your email"
                />
              </div>
            </div>
          
            <div className="col-md-6">
              <div className="Form-Group">
                <label htmlFor="mobile">Mobile No.:</label>
                <input
                  type="tel"
                  id="mobile"
                  name="mobile"
                  value={profileData.mobile}
                  onChange={handleProfileChange}
                  placeholder="Your mobile number"
                />
              </div>
            </div>
            </div>
            <div className="row">
            <div className="col-md-6">
              <div className="Form-Group">
                <label htmlFor="whatsapp">WhatsApp:</label>
                <input
                  type="tel"
                  id="whatsapp"
                  name="whatsapp"
                  value={profileData.whatsapp}
                  onChange={handleProfileChange}
                  placeholder="Your WhatsApp number"
                />
              </div>
            </div>
          <div className="col-md-6">
          <div className="Form-group">
            <label htmlFor="active">Active:</label>
            <input
              type="checkbox"
              id="active"
              name="active"
              value="Y"
              readOnly
              checked={profileData.active}
              onChange={() => setProfileData(prevData => ({ ...prevData, active: !prevData.active }))}
            />
          </div>
          </div>
          </div>
          <div className="Form-Group">
          <button type="submit" className="SUBMIT-button">Update</button>
          </div>
          </div>
        </div>
      </form>


      <div className="Panels-container">
        {/* Address Panel */}
      <div className="panel">
      <div className="panel-header" onClick={toggleAddressForm}>
        <span>Address</span>
        <button className="toggle-button">{showAddressForm ? '-' : '+'}</button>
      </div>

       {/* Display added addresses as a table */}
       {showAddressForm && addressList.length > 0 && (
        <div className="Table">
          <table>
            <thead>
              <tr>
                <th>Address Line 1</th>
                <th>Address Line 2</th>
                <th>City</th>
                <th>State</th>
                <th>Pin Code</th>
                <th>Country</th>
              </tr>
            </thead>
            <tbody>
              {addressList.map((address, index) => (
                <tr key={index}>
                  <td>{address.add1}</td>
                  <td>{address.add2}</td>
                  <td>{address.city}</td>
                  <td>{address.state}</td>
                  <td>{address.pin}</td>
                  <td>{address.country}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {showAddressForm && (
        <div className="panel-container">
          <form className="Panel" onSubmit={handleAddressSubmit}>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="add1">Building/Floor No.</label>
                  <input
                    type="text"
                    id="add1"
                    name="add1"
                    value={addressData.add1}
                    onChange={handleAddressChange}
                    className="form-control"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="add2">Street No./Name :</label>
                  <input
                    type="text"
                    id="add2"
                    name="add2"
                    value={addressData.add2}
                    onChange={handleAddressChange}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="pin">Pin Code:</label>
                  <input
                    type="text"
                    id="pin"
                    name="pin"
                    value={addressData.pin}
                    onChange={handleAddressChange}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                <label htmlFor="city">City:</label>
                  <input
                    type="text"
                    id="city"
                    name="city"
                    value={addressData.city}
                    onChange={handleAddressChange}
                    className="form-control"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                <label htmlFor="state">State:</label>
                  <input
                    type="text"
                    id="state"
                    name="state"
                    value={addressData.state}
                    onChange={handleAddressChange}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                <label htmlFor="country">Country:</label>
                  <input
                    type="text"
                    id="country"
                    name="country"
                    value={addressData.country}
                    onChange={handleAddressChange}
                    className="form-control"
                  />
                </div>
              </div>
            </div>

            <div className="Add-button">
              <button type="submit" className="SUBMIT-button">Add</button>
            </div>
          </form>
        </div>
      )}
      </div>

        {/* KYC Panel */}
      <div className="panel">
      <div className="panel-header" onClick={toggleKYCForm}>
        <span>KYC</span>
        <button className="toggle-button">{showKYCForm ? "-" : "+"}</button>
      </div>

      {showKYCData && kycData.length > 0 && (
        <div className="Table">
          <table>
            <thead>
              <tr>
                <th>Document Type</th>
                <th>Document No.</th>
                <th>Attachment</th>
              </tr>
            </thead>
            <tbody>
              {kycData.map((data, index) => (
                <tr key={index}>
                  <td>{data.docType}</td>
                  <td>{data.docNumber}</td>
                  <td>{data.attachment ? data.attachment.name : "No file"}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {showKYCForm && (
        <div className="panel-container">
          <form className="Panel" onSubmit={handleKYCSubmit}>
            <div className="row">
              <div className="Column">
                <div className="form-group">
                <label htmlFor="docType">Document Type:</label>
                  <select
                    id="docType"
                    name="docType"
                    className="form-control"
                    value={Dataform.docType}
                    onChange={handleChangeInput}
                  >
                    <option value="">-- Select --</option>
                    <option value="PAN">PAN</option>
                    <option value="Aadhar">Aadhar</option>
                    <option value="Driving License">Driving License</option>
                    <option value="Passport">Passport</option>
                    <option value="Voter ID">Voter ID</option>
                  </select>
                </div>
              </div>

              <div className="Column">
                <div className="form-group">
                <label htmlFor="docNumber">Document No. :</label>
                  <input
                    type="text"
                    id="docNumber"
                    name="docNumber"
                    className="form-control"
                    value={Dataform.docNumber}
                    onChange={handleChangeInput}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="Column">
                <div className="form-group">
                <label htmlFor="attachment">Attachment:</label>
                  <input
                    type="file"
                    id="attachment"
                    name="attachment"
                    className="form-control"
                    onChange={handleFileChange}
                  />
                </div>
              </div>
            </div>
            <div className="Add-button">
              <button type="submit" className="SUBMIT-button">
                Add KYC
              </button>
            </div>
          </form>
        </div>
      )}
      </div>
      </div>


    </div>
  );
};

export default MyProfile;
