import React from 'react'
import AdminHome from '../AdminHome';

const Dashboard = () => {
  return (
    <AdminHome>
    <div>Dashboard</div>
    </AdminHome>
  )
};

export default Dashboard;