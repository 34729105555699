import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../../ALL Css/ALL Dashboard CSS/Panels.css";



const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Panels = () => {
  const teacherId = localStorage.getItem('TeacherID');
  const [showAddressForm, setShowAddressForm] = useState(false);
  const [addressData, setAddressData] = useState({
    Add1: '',
    Add2: '',
    City: '',
    State: '',
    PinCode: '',
    Country: 'India',
    Landmark: '',
  });
  const [addressList, setAddressList] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [editingAddressId, setEditingAddressId] = useState(null);

  const [showEducationForm, setShowEducationForm] = useState(false);
  const [educationList, setEducationList] = useState([]);
  const [educationDetails, setEducationDetails] = useState({
    certificate: "",
    board: "",
    year: "",
    marks: "",
    grade: "",
  });
  const [isEditing, setIsEditing] = useState(false);
  const [editIndex, setEditIndex] = useState(null);

  const [showExperienceForm, setShowExperienceForm] = useState(false);
  const [experienceData, setExperienceData] = useState([]);
  const [formData, setFormData] = useState({
    organization: '',
    fromDate: '',
    toDate: '',
    designation: '',
    responsibilities: '',
    remarks: ''
  });

  const [showSubjectForm, setShowSubjectForm] = useState(false);
  const [board, setBoard] = useState('');
  const [classCode, setClassCode] = useState('');
  const [subject, setSubject] = useState('');
  const [subjectDetails, setSubjectDetails] = useState([]);

  const [showCentreForm, setShowCentreForm] = useState(false);
  const [centreCode, setCentreCode] = useState("");
  const [response, setResponse] = useState("");
  const [centreDetails, setCentreDetails] = useState([]);

  const [showKYCForm, setShowKYCForm] = useState(false);
  const [kycData, setKycData] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [showKYCData, setShowKYCData] = useState(false);
  const [Dataform, setDataForm] = useState({
    docType: "",
    docNumber: "",
    attachment: null,
    remarks: "",
  });


  const userData = JSON.parse(sessionStorage.getItem("user"));
  const SUCode = userData?.SUCode;
  const CreatedBy = userData?.UserType;
  const EmailID = userData?.EmailID;



  const toggleAddressForm = () => {
    setShowAddressForm(!showAddressForm);
    setAddressData({
      Add1: '',
      Add2: '',
      City: '',
      State: '',
      PinCode: '',
      Country: 'India',
      Landmark: '',
    });
    setIsUpdating(false);
    setEditingAddressId(null);
  };

  useEffect(() => {
    const fetchAddresses = async () => {
      const endpoint = `${API_BASE_URL}/api/teacher/${teacherId}/address`;

      try {
        const response = await axios.post(endpoint);
        setAddressList(Array.isArray(response.data) ? response.data : []);
      } catch (error) {
        console.error("Error fetching address list:", error);
        setAddressList([]);
      }
    };

    fetchAddresses();
  }, [teacherId]);

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    setAddressData((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddressSubmit = async (e) => {
    e.preventDefault();
    const existingAddress = addressList.length > 0;
    if (existingAddress && !isUpdating) {
      alert("You can only add your address once. Please update your existing address if needed.");
      return;
    }

    const endpoint = `${API_BASE_URL}/api/teacher/${teacherId}/address/update`;

    try {
      const response = await axios.post(endpoint, {
        ...addressData,
        SUCode,
        CreatedBy,
        EmailID,
      });

      const { respCode, respMsg } = response.data;
      alert(respMsg);

      if (respCode === "0") {
        // Refresh the address list after adding/updating
        const updatedList = isUpdating
          ? addressList.map((addr) =>
            addr.id === editingAddressId ? { ...addressData, id: editingAddressId } : addr
          )
          : [...addressList, { ...addressData, id: response.data.id }];
        setAddressList(updatedList);
        toggleAddressForm();
      }
    } catch (error) {
      console.error("Error updating address:", error);
      alert("Failed to update address. Please try again.");
    }
  };

  const handleEditAddress = (address) => {
    setAddressData(address);
    setIsUpdating(true);
    setEditingAddressId(address.id);
    setShowAddressForm(true);
  };




  // Toggle education form visibility
  const toggleEducationForm = () => {
    setShowEducationForm(!showEducationForm);
  };

  // Fetch education details when the component mounts
  useEffect(() => {
    const fetchEducationDetails = async () => {
      try {
        const response = await axios.post(`${API_BASE_URL}/getEducationDetails`, {
          teacherID: teacherId,
        });
        setEducationList(response.data);
      } catch (error) {
        console.error('Error fetching education details:', error);
      }
    };

    if (teacherId) {
      fetchEducationDetails();
    }
  }, [teacherId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEducationDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleEducationFormSubmit = (e) => {
    e.preventDefault();

    if (isEditing) {
      const updatedEducationList = [...educationList];
      updatedEducationList[editIndex] = educationDetails;
      setEducationList(updatedEducationList);

    } else {
      setEducationList([...educationList, educationDetails]);
    }

    setEducationDetails({
      certificate: "",
      board: "",
      year: "",
      marks: "",
      grade: "",
    });
    setIsEditing(false);
    setEditIndex(null);
  };

  const handleEditClick = (index) => {
    setEducationDetails(educationList[index]);
    setIsEditing(true);
    setEditIndex(index);
    setEducationDetails(educationList[index]);
  };




  // Toggle experience form visibility
  const toggleExperienceForm = () => {
    setShowExperienceForm(!showExperienceForm);
  };

  useEffect(() => {
    const fetchExperienceData = async () => {
      try {
        const response = await axios.post(`${API_BASE_URL}/getExperience`, {
          teacherID: teacherId,
        });

        if (response.data.respCode === '0') {
          setExperienceData(response.data.experienceData);
        } else {
          alert(response.data.respMsg);
        }
      } catch (error) {
        alert('Error occurred while fetching experience data');
      }
    };

    if (teacherId) {
      fetchExperienceData();
    }
  }, [teacherId]);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${API_BASE_URL}/addExperience`, {
        SUCode: SUCode,
        CreatedBy: CreatedBy,
        TeacherID: teacherId,
        experienceData: formData,
        EmailID: EmailID,
      });

      if (response.data.respCode === '0') {
        alert('Experience added successfully');
        setExperienceData((prevData) => [
          ...prevData,
          formData,
        ]);
        setFormData({
          organization: '',
          fromDate: '',
          toDate: '',
          designation: '',
          responsibilities: '',
          remarks: ''
        });
      } else {
        alert(response.data.respMsg);
      }
    } catch (error) {
      alert('Error occurred while adding experience');
    }
  };





  // Toggle Subject form visibility
  const toggleSubjectForm = () => {
    setShowSubjectForm(!showSubjectForm);
  };

  useEffect(() => {
    const fetchSubjectDetails = async () => {
      try {
        const response = await axios.post(`${API_BASE_URL}/api/get-teacher-subjects`, {
          teacherID: teacherId,
        });

        if (response.data.respCode === '0') {
          setSubjectDetails(response.data.subjectDetails); // Update state with subject details
        } else {
          alert(response.data.respMsg); // Show message if no subjects found
        }
      } catch (error) {
        alert('Error occurred while fetching subject data');
      }
    };

    if (teacherId) {
      fetchSubjectDetails();
    }
  }, [teacherId]);

  const handleSubjectFormSubmit = async (e) => {
    e.preventDefault();

    const formData = {
      SUCode,
      TeacherID: teacherId,
      BoardCode: board,
      SubjectCode: subject,
      ClassCode: classCode,
      CreatedBy,
      CreaterSUCode: SUCode,
      CreaterFormName: 'Manageteacher.js',
      CreaterEmailID: EmailID,
    };

    try {
      const response = await axios.post(`${API_BASE_URL}/api/insert-teacher-subject`, formData);

      if (response.data.RespCode === '0') {
        alert(response.data.RespMsg);
      } else {
        alert(response.data.RespMsg);
      }
    } catch (error) {
      console.error('Error inserting subject:', error);
      alert('An error occurred while saving the data.');
    }
  };





  // Toggle Centre form visibility
  const toggleCentreForm = () => {
    setShowCentreForm(!showCentreForm);
  };

  useEffect(() => {
    if (teacherId) {
      const fetchCentreCode = async () => {
        try {
          const res = await axios.post(`${API_BASE_URL}/api/teacher-centre`, {
            teacherID: teacherId,
          });

          if (res.data.respCode === "0" && res.data.centreDetails.length > 0) {
            setCentreDetails(res.data.centreDetails);
          } else {
            setResponse("No CentreCode found for this TeacherID.");
          }
        } catch (error) {
          console.error("Error fetching centre code:", error);
          setResponse("Failed to fetch CentreCode.");
        }
      };

      fetchCentreCode();
    }
  }, [teacherId]);


  const handleCentreCodeChange = (e) => {
    setCentreCode(e.target.value);
  };

  const handleCentreFormSubmit = async (e) => {
    e.preventDefault();

    const userData = JSON.parse(sessionStorage.getItem("user"));
    const SUCode = userData?.SUCode;
    const CreatedBy = userData?.UserType;
    const EmailID = userData?.EmailID;

    const data = {
      teacherId,
      CentreCode: centreCode,
      SUCode,
      CreatedBy,
      EmailID,
    };

    try {
      const res = await axios.post(`${API_BASE_URL}/api/teacher-centre`, data);
      if (res.data.RespCode === "0") {
        alert(res.data.RespMsg);
        setResponse(res.data.RespMsg);
        setCentreCode("");
      } else {
        alert(res.data.RespMsg);
      }
    } catch (error) {
      console.error("Error adding Centre details:", error);
      alert("Failed to add Centre. Please try again later.");
    }
  };





  // Toggle KYC form visibility
  const toggleKYCForm = () => {
    setShowKYCForm(!showKYCForm);
    setShowKYCData(false);
  };

  const fetchKYCData = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/getKYCDetails`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ TeacherID: teacherId }),
      });

      const result = await response.json();

      if (result.respCode === "0") { // Success response
        setKycData(result.kycDetails);
        setShowKYCData(true);
      } else {
        alert(result.respMsg);
        setShowKYCData(false);
      }
    } catch (error) {
      console.error("Error fetching KYC details:", error);
      alert("Failed to fetch KYC details.");
    }
  };

  useEffect(() => {
    if (teacherId) {
      fetchKYCData();
    } else {
      alert("TeacherID is missing in local storage.");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teacherId]);

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setDataForm({
      ...Dataform,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    setDataForm({
      ...Dataform,
      attachment: e.target.files[0],
    });
  };

  const handleKYCSubmit = async (e) => {
    e.preventDefault();

    // Prepare FormData object
    const formData = new FormData();
    formData.append("SUCode", SUCode);
    formData.append("CreatedBy", CreatedBy);
    formData.append("EmailID", EmailID);
    formData.append("teacherID", teacherId);
    formData.append("KYCType", Dataform.docType);
    formData.append("DocNumber", Dataform.docNumber);
    formData.append("Remarks", Dataform.remarks);
    formData.append("attachment", Dataform.attachment);

    try {
      const response = await fetch(`${API_BASE_URL}/api/submitKYC`, {
        method: "POST",
        body: formData,
      });

      const result = await response.json();
      if (result.status === 0) {
        alert(result.message);
        setKycData([
          ...kycData,
          {
            ...Dataform,
            docType: Dataform.docType,
            docNumber: Dataform.docNumber,
            attachment: Dataform.attachment ? Dataform.attachment.name : "No file",
            remarks: Dataform.remarks,
          },
        ]);
        setShowKYCData(true);
      } else {
        alert(result.message);
      }
    } catch (error) {
      console.error("Error while submitting KYC:", error);
      alert("Failed to submit KYC details.");
    }
    setDataForm({
      docType: "",
      docNumber: "",
      attachment: null,
      remarks: "",
    });
  };




  return (
    <div className="panels-container">


      {/* Address Panel */}
      <div className="panel">
        <div className="panel-header" onClick={toggleAddressForm}>
          <span>Address</span>
          <button className="toggle-button">{showAddressForm ? '-' : '+'}</button>
        </div>

        {showAddressForm && (
          <>
            {addressList.length > 0 ? (
              <div className="Table">
                <table>
                  <thead>
                    <tr>
                      <th>Address Line 1</th>
                      <th>Address Line 2</th>
                      <th>City</th>
                      <th>State</th>
                      <th>Pin Code</th>
                      <th>Country</th>
                      <th>Landmark</th>
                      <th>Edit</th>
                    </tr>
                  </thead>
                  <tbody>
                    {addressList.map((address, index) => (
                      <tr key={index}>
                        <td>{address.Add1}</td>
                        <td>{address.Add2}</td>
                        <td>{address.City}</td>
                        <td>{address.State}</td>
                        <td>{address.PinCode}</td>
                        <td>{address.Country}</td>
                        <td>{address.Landmark}</td>
                        <td>
                          <label
                            className="Edit-button"
                            onClick={() => handleEditAddress(address)}
                          >
                            ✏️
                          </label>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="no-address">
              </div>
            )}

            <div className="panel-container">
              <form className="Panel" onSubmit={handleAddressSubmit}>
                <div className="row">
                  <div className="Column">
                    <div className="form-group">
                      <label htmlFor="add1">Address Line 1:</label>
                      <input
                        type="text"
                        id="add1"
                        name="add1"
                        value={addressData.Add1}
                        onChange={handleAddressChange}
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div className="Column">
                    <div className="form-group">
                      <label htmlFor="add2">Address Line 2:</label>
                      <input
                        type="text"
                        id="add2"
                        name="add2"
                        value={addressData.Add2}
                        onChange={handleAddressChange}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="Column">
                    <div className="form-group">
                      <label htmlFor="city">City:</label>
                      <input
                        type="text"
                        id="city"
                        name="city"
                        value={addressData.City}
                        onChange={handleAddressChange}
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div className="Column">
                    <div className="form-group">
                      <label htmlFor="pin">Pin Code:</label>
                      <input
                        type="text"
                        id="pin"
                        name="pin"
                        value={addressData.PinCode}
                        onChange={handleAddressChange}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="Column">
                    <div className="form-group">
                      <label htmlFor="state">State:</label>
                      <select
                        id="state"
                        name="state"
                        value={addressData.State}
                        onChange={handleAddressChange}
                        className="form-control"
                      >
                        <option value="">Select State---</option>
                        <option value="Andhra Pradesh">Andhra Pradesh</option>
                        <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                        <option value="Assam">Assam</option>
                        <option value="Bihar">Bihar</option>
                        <option value="Chhattisgarh">Chhattisgarh</option>
                        <option value="Delhi">Delhi</option>
                        <option value="Goa">Goa</option>
                        <option value="Gujarat">Gujarat</option>
                        <option value="Haryana">Haryana</option>
                        <option value="Himachal Pradesh">Himachal Pradesh</option>
                        <option value="Jharkhand">Jharkhand</option>
                        <option value="Karnataka">Karnataka</option>
                        <option value="Kerala">Kerala</option>
                        <option value="Madhya Pradesh">Madhya Pradesh</option>
                        <option value="Maharashtra">Maharashtra</option>
                        <option value="Manipur">Manipur</option>
                        <option value="Meghalaya">Meghalaya</option>
                        <option value="Mizoram">Mizoram</option>
                        <option value="Nagaland">Nagaland</option>
                        <option value="Odisha">Odisha</option>
                        <option value="Puducherry">Puducherry</option>
                        <option value="Punjab">Punjab</option>
                        <option value="Rajasthan">Rajasthan</option>
                        <option value="Sikkim">Sikkim</option>
                        <option value="Tamil Nadu">Tamil Nadu</option>
                        <option value="Telangana">Telangana</option>
                        <option value="Tripura">Tripura</option>
                        <option value="Uttar Pradesh">Uttar Pradesh</option>
                        <option value="Uttarakhand">Uttarakhand</option>
                        <option value="West Bengal">West Bengal</option>
                      </select>
                    </div>
                  </div>

                  <div className="Column">
                    <div className="form-group">
                      <label htmlFor="country">Country:</label>
                      <select
                        id="country"
                        name="country"
                        value={addressData.Country}
                        onChange={handleAddressChange}
                        className="form-control"
                      >
                        <option value="India">India</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="Column">
                    <div className="form-group">
                      <label htmlFor="landmark">Landmark:</label>
                      <input
                        type="text"
                        id="landmark"
                        name="landmark"
                        value={addressData.Landmark}
                        onChange={handleAddressChange}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div className="Add-Button">
                  <button className="submit-button">
                    {isUpdating ? 'Update Address' : 'Add Address'}
                  </button>
                </div>
              </form>
            </div>
          </>
        )}
      </div>


      {/* Education Panel */}
      <div className="panel">
        <div className="panel-header" onClick={toggleEducationForm}>
          <span>Education</span>
          <button className="toggle-button">{showEducationForm ? "-" : "+"}</button>
        </div>

        {showEducationForm && educationList.length > 0 && (
          <div className="Table">
            <table>
              <thead>
                <tr>
                  <th>Certificate</th>
                  <th>Board</th>
                  <th>Year</th>
                  <th>Marks</th>
                  <th>Grade</th>
                  <th>Edit</th>
                </tr>
              </thead>
              <tbody>
                {educationList.map((education, index) => (
                  <tr key={index}>
                    <td>{education.certificate}</td>
                    <td>{education.board}</td>
                    <td>{education.year}</td>
                    <td>{education.marks}</td>
                    <td>{education.grade}</td>
                    <td><label className="Edit-button"
                      onClick={() => handleEditClick(index)}>
                      ✏️
                    </label></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {/* Education Form */}
        {showEducationForm && (
          <div className="panel-container">
            <form className="Panel" onSubmit={handleEducationFormSubmit}>
              <div className="row">
                <div className="Column">
                  <div className="form-group">
                    <label htmlFor="certificate">Certificate:</label>
                    <input
                      type="text"
                      id="certificate"
                      name="certificate"
                      className="form-control"
                      value={educationDetails.certificate}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="Column">
                  <div className="form-group">
                    <label htmlFor="board">Board:</label>
                    <input
                      type="text"
                      id="board"
                      name="board"
                      className="form-control"
                      value={educationDetails.board}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>

              {isEditing && (
                <>
                  <div className="row">
                    <div className="Column">
                      <div className="form-group">
                        <label htmlFor="year">Year:</label>
                        <input
                          type="text"
                          id="year"
                          name="year"
                          className="form-control"
                          value={educationDetails.year}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="Column">
                      <div className="form-group">
                        <label htmlFor="marks">Marks:</label>
                        <input
                          type="text"
                          id="marks"
                          name="marks"
                          className="form-control"
                          value={educationDetails.marks}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="Column">
                      <div className="form-group">
                        <label htmlFor="grade">Grade:</label>
                        <input
                          type="text"
                          id="grade"
                          name="grade"
                          className="form-control"
                          value={educationDetails.grade}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}

              <div className="Add-button">
                <button type="submit" className="SUBMIT-button">
                  {isEditing ? "Update" : "Add"}
                </button>
              </div>
            </form>
          </div>
        )}
      </div>


      {/* Experience Panel */}
      <div className="panel">
        <div className="panel-header" onClick={toggleExperienceForm}>
          <span>Experience</span>
          <button className="toggle-button">{showExperienceForm ? "-" : "+"}</button>
        </div>
        {/* Table to display experience data */}
        {showExperienceForm && experienceData.length > 0 && (
          <div className="Table">
            <table>
              <thead>
                <tr>
                  <th>Organization</th>
                  <th>From Date</th>
                  <th>To Date</th>
                  <th>Designation</th>
                  <th>Responsibilities</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody>
                {experienceData.map((experience, index) => (
                  <tr key={index}>
                    <td>{experience.Organisation}</td>
                    <td>{experience.FromDate}</td>
                    <td>{experience.ToDate}</td>
                    <td>{experience.Designation}</td>
                    <td>{experience.Responsibilities}</td>
                    <td>{experience.Remarks}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {showExperienceForm && (
          <div className="panel-container">
            <form className="Panel" onSubmit={handleSubmit}>

              <div className="row">
                <div className="Column">
                  <div className="form-group">
                    <label htmlFor="organization">Organization:</label>
                    <input
                      type="text"
                      id="organization"
                      name="organization"
                      className="form-control"
                      value={formData.organization}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="Column">
                  <div className="form-group">
                    <label htmlFor="fromDate">From Date:</label>
                    <input
                      type="date"
                      id="fromDate"
                      name="fromDate"
                      className="form-control"
                      value={formData.fromDate}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="Column">
                  <div className="form-group">
                    <label htmlFor="toDate">To Date:</label>
                    <input
                      type="date"
                      id="toDate"
                      name="toDate"
                      className="form-control"
                      value={formData.toDate}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="Column">
                  <div className="form-group">
                    <label htmlFor="designation">Designation:</label>
                    <input
                      type="text"
                      id="designation"
                      name="designation"
                      className="form-control"
                      value={formData.designation}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="Column">
                  <div className="form-group">
                    <label htmlFor="responsibilities">Responsibilities:</label>
                    <textarea
                      id="responsibilities"
                      name="responsibilities"
                      className="form-control"
                      value={formData.responsibilities}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="Column">
                  <div className="form-group">
                    <label htmlFor="remarks">Remarks:</label>
                    <textarea
                      id="remarks"
                      name="remarks"
                      className="form-control"
                      value={formData.remarks}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>

              {/* Submit Button */}
              <div className="Add-button">
                <div className="Column">
                  <button type="submit" className="SUBMIT-button">Add Experience</button>
                </div>
              </div>
            </form>
          </div>
        )}
      </div>


      {/* Subject Prefrance*/}
      <div className="panel">
        <div className="panel-header" onClick={toggleSubjectForm}>
          <span>Subject</span>
          <button className="toggle-button">{showSubjectForm ? "-" : "+"}</button>
        </div>
        {showSubjectForm && subjectDetails.length > 0 && (
          <div className="Table">
            <table>
              <thead>
                <tr>
                  <th>Board</th>
                  <th>Class</th>
                  <th>Subject</th>
                </tr>
              </thead>
              <tbody>
                {subjectDetails && Array.isArray(subjectDetails) && subjectDetails.length > 0 ? (
                  subjectDetails.map((item) => (
                    <tr key={item.SUCode}>
                      <td>{item.BoardCode}</td>
                      <td>{item.ClassCode}</td>
                      <td>{item.SubjectCode}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3">No subjects available.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}

        {showSubjectForm && (
          <div className="panel-container">
            <form className="Panel" onSubmit={handleSubjectFormSubmit}>
              <div className="row">
                <div className="Column">
                  <div className="form-group">
                    <label htmlFor="board">Board:</label>
                    <input type="text"
                      id="board"
                      name="board"
                      className="form-control"
                      value={board}
                      onChange={(e) => setBoard(e.target.value)}
                    />
                  </div>
                </div>

                <div className="Column">
                  <div className="form-group">
                    <label htmlFor="class">Class:</label>
                    <input type="text"
                      id="class"
                      name="class"
                      className="form-control"
                      value={classCode}
                      onChange={(e) => setClassCode(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="Column">
                  <div className="form-group">
                    <label htmlFor="subject">Subject:</label>
                    <input type="text"
                      id="subject"
                      name="subject"
                      className="form-control"
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="Add-button">
                <button type="submit" className="SUBMIT-button">Add</button>
              </div>
            </form>
          </div>
        )}
      </div>


      {/* Centre Prefrance*/}
      <div className="panel">
        <div className="panel-header" onClick={toggleCentreForm}>
          <span>Centre</span>
          <button className="toggle-button">{showCentreForm ? "-" : "+"}</button>
        </div>
        {showCentreForm && centreDetails.length > 0 && (
          <div className="Table">
            <table>
              <thead>
                <tr>
                  <th>Centre Code</th>
                </tr>
              </thead>
              <tbody>
                {centreDetails.length > 0 ? (
                  centreDetails.map((centre, index) => (
                    <tr key={index}>
                      <td>{centre.CentreCode}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4">No data available</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}

        {showCentreForm && (
          <div className="panel-container">
            <form className="Panel" onSubmit={handleCentreFormSubmit}>
              <div className="row">
                <div className="Column">
                  <div className="form-group">
                    <label htmlFor="centre">Centre Code:</label>
                    <input type="text"
                      id="centre"
                      name="centre"
                      className="form-control"
                      value={centreCode}
                      onChange={handleCentreCodeChange}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="Add-button">
                <button type="submit" className="SUBMIT-button">Add</button>
              </div>
            </form>
            {response && <p>{response}</p>}
          </div>
        )}
      </div>


      {/* KYC Panel */}
      <div className="panel">
        <div className="panel-header" onClick={toggleKYCForm}>
          <span>KYC</span>
          <button className="toggle-button">{showKYCForm ? "-" : "+"}</button>
        </div>

        {showKYCForm && kycData.length > 0 &&  (
          <div className="Table">
            <table>
              <thead>
                <tr>
                  <th>Document Type</th>
                  <th>Document No.</th>
                  <th>Attachment</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody>
                {kycData.map((data, index) => (
                  <tr key={index}>
                    <td>{data.KYCType}</td>
                    <td>{data.DocNumber}</td>
                    <td>
                      {data.FileData ? (
                        <a
                          href={`data:${data.FileType};base64,${data.FileData}`}
                          download={`attachment-${index}`}
                        >
                          Download
                        </a>
                      ) : (
                        "No attachment"
                      )}
                    </td>
                    <td>{data.Remarks}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {showKYCForm && (
          <div className="panel-container">
            <form className="Panel" onSubmit={handleKYCSubmit}>
              <div className="row">
                <div className="Column">
                  <div className="form-group">
                    <label htmlFor="docType">Document Type:</label>
                    <select
                      id="docType"
                      name="docType"
                      className="form-control"
                      value={Dataform.docType}
                      onChange={handleChangeInput}
                      required
                    >
                      <option value="">-- Select --</option>
                      <option value="PAN">PAN</option>
                      <option value="Aadhar">Aadhar</option>
                      <option value="Driving License">Driving License</option>
                      <option value="Passport">Passport</option>
                      <option value="Voter ID">Voter ID</option>
                    </select>
                  </div>
                </div>

                <div className="Column">
                  <div className="form-group">
                    <label htmlFor="docNumber">Document No. :</label>
                    <input
                      type="text"
                      id="docNumber"
                      name="docNumber"
                      className="form-control"
                      value={Dataform.docNumber}
                      onChange={handleChangeInput}
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="Column">
                  <div className="form-group">
                    <label htmlFor="attachment">Attachment:</label>
                    <input
                      type="file"
                      id="attachment"
                      name="attachment"
                      className="form-control"
                      onChange={handleFileChange}
                      required
                    />
                  </div>
                </div>

                <div className="Column">
                  <div className="form-group">
                    <label htmlFor="remarks">Remarks:</label>
                    <input
                      type="text"
                      id="remarks"
                      name="remarks"
                      className="form-control"
                      onChange={handleChangeInput}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="Add-button">
                <button type="submit" className="SUBMIT-button">
                  Add KYC
                </button>
              </div>
            </form>
          </div>
        )}
      </div>



    </div>
  );
};

export default Panels;
