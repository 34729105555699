import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

const StudyMaterials = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/boarddetails');
  }, [navigate]);
  

  return (
    <div>
      Navigating to Board Details...
    </div>
  );
};

export default StudyMaterials;
