import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../../../ALL Css/ALL Dashboard CSS/ClassDetails.css';
import AdminHome from '../AdminHome';



const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const ClassDetails = () => {
  const { suCode, boardCode } = useParams();
  const [classes, setClasses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [breadcrumb, setBreadcrumb] = useState('');
  const navigate = useNavigate();

 
  useEffect(() => {
    const fetchClasses = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${API_BASE_URL}/api/classes`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            suCode: suCode, 
            boardCode: boardCode,
          }),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setClasses(data);
      } catch (error) {
        console.error('Failed to fetch classes:', error.message);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchClasses();
  }, [suCode, boardCode]);

  const handleClassClick = (classCode, className, boardName) => {
    const parentBreadcrumb = sessionStorage.getItem('breadcrumb'); 
    const newBreadcrumb = `${parentBreadcrumb} > ${className}`; 
    setBreadcrumb(newBreadcrumb);
    sessionStorage.setItem('breadcrumb', newBreadcrumb); 
    navigate(`/subject-details/${suCode}/${boardCode}/${classCode}`, { state: {
        boardName: boardName, 
        className:className,  
      } });
  };

  useEffect(() => {
    const storedBreadcrumb = sessionStorage.getItem('breadcrumb');
    if (storedBreadcrumb) {
      const breadcrumbParts = storedBreadcrumb.split(' > ');
      if (breadcrumbParts.length > 3) {
        breadcrumbParts.pop(); 
        setBreadcrumb(breadcrumbParts.join(' > '));
        sessionStorage.setItem('breadcrumb', breadcrumbParts.join(' > '));
      }
    }
  }, [suCode, boardCode]);

  return (
    <AdminHome>
      <div className="class-details">
      <div className="breadcrumb">
          <p style={{ display: 'none' }}>{breadcrumb}</p>
        </div>
        {loading && <p>Loading...</p>}
        {error && <p>{error}</p>}
        {classes.map((classItem) => (
          <div key={classItem.ClassCode} className="class-item">
            <button
              onClick={() => handleClassClick(classItem.ClassCode, classItem.ClassName)}
              className="class-button"
            >
              <span>{classItem.ClassName}</span>
            </button>
          </div>
        ))}
      </div>
    </AdminHome>
  );
};

export default ClassDetails;
