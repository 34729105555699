import React, { useEffect, useState, useRef, lazy, Suspense } from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import axios from 'axios';
import { useNavigate,useLocation } from 'react-router-dom';
import '../../ALL Css/ALL Dashboard CSS/AdminHome.css';


const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const AdminHome = ({ children }) => {
  const [menuData, setMenuData] = useState([]);
  const [expandedMenu, setExpandedMenu] = useState(null);
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [isProfilePopupVisible, setIsProfilePopupVisible] = useState(false);
  const [LoadedComponent, setLoadedComponent] = useState(null);
  const [breadcrumb, setBreadcrumb] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const profilePopupRef = useRef(null);

  // eslint-disable-next-line no-unused-vars
  const [profileData, setProfileData] = useState({});
  const [photo, setPhoto] = useState(null);

 

  useEffect(() => {
    const storedBreadcrumb = sessionStorage.getItem('breadcrumb');
    if (storedBreadcrumb) {
      setBreadcrumb(storedBreadcrumb);
    } else {
      setBreadcrumb(''); 
    }
  }, [location.pathname]);
  

  const iconMapping = {
    "Dashboard": "bi bi-house",
    "User Management": "bi bi-person",
    "Student enrollment": "bi bi-file-earmark-person",
    "Batch Management": "bi bi-calendar",
    "Teacher": "bi bi-person-check",
    "LMS`": "bi bi-book",
    "Exam management portal": "bi bi-file-earmark-text",
    "Inventory Management": "bi bi-box",
    "Demo class management system": "bi bi-play-circle",
    "Communication": "bi bi-chat",
    "Doubt solving classes credit system": "bi bi-question-circle",
  };

  useEffect(() => {
    const fetchMenuData = async () => {
      try {
        const storedUserDetails = JSON.parse(sessionStorage.getItem('user'));
        const response = await fetch(`${API_BASE_URL}/menus?SUCode=${storedUserDetails.SUCode}&UserType=${storedUserDetails.UserType}&EMailID=${storedUserDetails.EmailID}`, {
          method: 'POST',
        });
        if (!response.ok) throw new Error('Network response was not ok');
        const data = await response.json();
        setMenuData(data);
      } catch (error) {
        setError('Error fetching menu: ' + error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchMenuData();
  }, []);

  const toggleSubmenu = (menu) => {
    if (!isSidebarVisible) {
      setIsSidebarVisible(true);
    }
    setExpandedMenu((prev) => (prev === menu.MenuCode ? null : menu.MenuCode));
    setBreadcrumb(menu.MenuName);
    sessionStorage.setItem('breadcrumb', menu.MenuName); 
  };

  const handleSubmenuClick = (submenu, parentMenuName) => {
    const fullBreadcrumb = `${parentMenuName} > ${submenu.MenuName}`;
    setBreadcrumb(fullBreadcrumb);
    sessionStorage.setItem('breadcrumb', fullBreadcrumb); 
    const Component = lazy(() => import(`../../Components/AdminPart/AdminDetails/${submenu.FrmName}.js`));
    setLoadedComponent(() => Component);
  };

  // Clear breadcrumb when navigating back from submenu to menu
  useEffect(() => {
    if (location.pathname === '/adminhome') {  
      sessionStorage.removeItem('breadcrumb');
      setBreadcrumb('');
    }
  }, [location.pathname]);

  const renderMenuItems = () => {
    const level1Menus = menuData.filter((item) => item.MenuLevel === 'Level1');
    return level1Menus.map((menu) => (
      <div key={menu.MenuCode}>
        <div
          onClick={() => toggleSubmenu(menu)}
          style={{
            fontFamily: '"Gill Sans", sans-serif',
            cursor: 'pointer',
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
            padding: '10px 20px',
          }}
        >
          {isSidebarVisible && (
            <>
              <i
                className={iconMapping[menu.MenuName]}
                style={{
                  marginRight: '15px',
                  fontSize: isSidebarVisible ? '20px' : '30px', 
                  transition: 'font-size 0.3s ease',
                }}
              ></i>
              {menu.MenuName}
            </>
          )}
          <span
            style={{
              marginLeft: 'auto',
              transition: 'transform 0.3s',
              transform: expandedMenu === menu.MenuCode ? 'rotate(180deg)' : 'rotate(0deg)',
            }}
          >
            <i className="bi bi-chevron-down"></i>
          </span>
        </div>
        {expandedMenu === menu.MenuCode && renderSubmenuItems(menu.MenuCode, menu.MenuName)}
      </div>
    ));
  };
  

  const renderSubmenuItems = (parentMenuCode, parentMenuName) => {
    const level2Submenus = menuData.filter((submenu) => submenu.ParentCode === parentMenuCode && submenu.MenuLevel === 'Level2');
    if (level2Submenus.length === 0) return null;

    return (
      <div className="submenu">
        {level2Submenus.map((submenu) => (
          <div key={submenu.MenuCode} className="submenu-item">
            <div
              onClick={() => handleSubmenuClick(submenu, parentMenuName)}
              style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
            >
              {submenu.MenuName}
            </div>
          </div>
        ))}
      </div>
    );
  };

  const toggleSidebar = () => {
    setIsSidebarVisible((prev) => !prev);
  };

  const togglePopup = () => {
    setIsProfilePopupVisible((prev) => !prev);
    if (!isProfilePopupVisible) {
      const storedUserDetails = JSON.parse(sessionStorage.getItem('user'));
      if (storedUserDetails) {
        setUserDetails(storedUserDetails);
      }
    }
  };

  useEffect(() => {
    const UserDetails = JSON.parse(sessionStorage.getItem("user"));
    if (UserDetails?.EmailID) {
      axios
        .post(`${API_BASE_URL}/api/profile`, { EmailID: UserDetails.EmailID })
        .then((response) => {
          const { UserName, EmailID, Mobile, WhatsApp, Active, UserImage } = response.data;
          setProfileData({
            name: UserName,
            email: EmailID,
            mobile: Mobile,
            whatsapp: WhatsApp,
            active: Active,
          });
          if (UserImage) {
            setPhoto(`${API_BASE_URL}${UserImage}`);
          }
        })
        .catch((error) => {
          console.error("Error fetching profile data", error);
        });
    } else {
      console.error("No email ID found in session storage");
    }
  }, []);

  const handleLogout = () => {
    sessionStorage.removeItem('user');
    setUserDetails(null);
    navigate('/loginselect');
  };

  const handleLogoClick = () => {
    navigate('/dashboard');
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (profilePopupRef.current && !profilePopupRef.current.contains(event.target)) {
        setIsProfilePopupVisible(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="Container">
      <nav className="Navbar">
        <div className="Funscholar-Logo" style={{ cursor: 'pointer' }} onClick={handleLogoClick}>
          <img src="/fabicon.ico" alt="Funscholar Logo" />
        </div>
        <div className="Navbar-Heading">
          {breadcrumb}
        </div>
        <div className="profile-container" onClick={togglePopup}>
        <div className="profile-icon">
        {photo ? (
          <img
            src={photo}
            alt="Profile"
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              objectFit: "cover",
              marginRight: "40px",
            }}
          />
        ) : (
          <i
            className="bi bi-person-circle"
            style={{ fontSize: "30px", marginRight: "50px" }}
          ></i>
        )}
      </div>
        </div>
        {isProfilePopupVisible && userDetails && (
          <div className="profile-popup" ref={profilePopupRef}>
            <p><strong>UserType:</strong> {userDetails.UserType}</p>
            <p><strong>SUCode:</strong> {userDetails.SUCode}</p>
            <p><strong>User Name:</strong> {userDetails.UserName}</p>
            <p><strong>Mobile Number:</strong> {userDetails.Mobile}</p>
            <p><strong>Whatsapp:</strong> {userDetails.WhatsApp}</p>
            <p><strong>Email Id:</strong> {userDetails.EmailID}</p>
            <p><strong>Company Code:</strong> {userDetails.CompCode}</p>
            <p><strong>Company Name:</strong> {userDetails.CompName}</p>
            <button onClick={handleLogout}>Logout</button>
          </div>
        )}
      </nav>
      <div className="container-fluid">
        <div className="sidebar-container">
          {isSidebarVisible ? (
            <div className="sidebar">{renderMenuItems()}</div>
          ) : (
            <div className="sidebar-collapsed">
              {menuData.map((menu) => (
                <div key={menu.MenuCode} className="collapsed-menu" onClick={() => toggleSubmenu(menu)}>
                  <i className={iconMapping[menu.MenuName]}></i>
                </div>
              ))}
            </div>
          )}

          {/* Arrow icon to toggle sidebar */}
          <div className="sidebar-toggle-btn" onClick={toggleSidebar}>
            <i className={`bi bi-arrow-${isSidebarVisible ? 'left' : 'right'}`}></i>
          </div>
        </div>
        <div className="Content">
          {children || (LoadedComponent && <Suspense fallback={<div>Loading...</div>}><LoadedComponent /></Suspense>)}
        </div>
      </div>
    </div>
  );
};

export default AdminHome;
